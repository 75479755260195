<script>
import HomePanelActions from './home_panel_actions.vue';

export default {
  components: {
    HomePanelActions,
  },
};
</script>

<template>
  <home-panel-actions />
</template>
